import React, { useState, useEffect, useRef } from "react";
import dangerIcon from "../../assets/icons/exclamation.png";
import search from "../../assets/icons/search.png";
import eye from "../../assets/icons/eye.svg";
import eyeCrossedOut from "../../assets/icons/eye-crossed-out.svg";
import { CustomButton } from "../CustomButton/CustomButton";
import "./input.scss";

export const CustomInput = ({
  type,
  formData,
  placeholder,
  onChange,
  isSearch,
  onBlur,
  maxLength = 255,
  disabled,
  variantError = "aside" /* aside | topright | bottomleft */,
  customInputStyle = {},
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animation, setAnimation] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 200);
  }, [formData]);

  const handleChange = (e) => {
    setAnimation(false);
    onChange(e);
  };

  const getType = () => {
    if (type === "password") {
      if (isVisible) {
        return "text";
      } else {
        return "password";
      }
    }
    return type || "text";
  };

  const handleShow = () => {
    setIsVisible((prev) => !prev);
  };

  const isVisibleEye = type === "password";

  const getEyeStyle = () => {
    if (formData?.touched && formData?.hasError && variantError === "aside") {
      return {
        //paddingRight: '35px'
      };
    }
    return {};
  };
  const getInputErrorStyle = () => {
    if (formData?.hasError && formData?.touched && variantError === "aside") {
      return {
        boxShadow: "0px 1.5px 0px #BE1111CC",
        border: "initial",
      };
    } else {
    }
  };

  return (
    <>
      <div
        style={{ ...getInputErrorStyle(), ...customInputStyle }}
        className={`custom-input ${
          formData?.hasError && formData?.touched ? "custom-input_error" : ""
        }`}
        ref={ref}
      >
        <input
          className={`custom-input__input ${
            formData?.hasError && formData?.touched
              ? "custom-input__input_error"
              : ""
          }`}
          type={getType()}
          placeholder={placeholder || ""}
          value={formData?.value || ""}
          onChange={handleChange}
          maxLength={maxLength}
          onBlur={onBlur}
          disabled={disabled}
          {...props}
        />

        {isSearch && (
          <div className="custom-input-icon-container">
            <img alt="search" src={search} className="custom-input-icon" />
          </div>
        )}

        {isVisibleEye && (
          <div
            className="custom-input-icon-container pointer"
            onClick={handleShow}
            style={getEyeStyle()}
          >
            <img
              alt="eye"
              src={isVisible ? eyeCrossedOut : eye}
              className="custom-input-icon"
            />
          </div>
        )}

        {formData?.touched &&
          formData?.hasError &&
          variantError === "aside" && (
            <>
              <img
                className={`custom-input__image ${
                  animation ? "custom-input__image_animated" : ""
                }`}
                src={dangerIcon}
                alt="error"
              />
              {formData?.error && (
                <div
                  className={`custom-input__err-container ${
                    animation ? "custom-input__err-container_animated" : ""
                  }`}
                >
                  <div className="custom-input__err-text">
                    {formData?.error}
                  </div>
                </div>
              )}
            </>
          )}
      </div>
      {formData?.touched &&
        formData?.hasError &&
        ["topright", "bottomleft"].includes(variantError) && (
          <div className={`custom-input__err ${variantError}`}>
            {formData?.error}
          </div>
        )}
    </>
  );
};

export const CustomInputPassword = ({
  type,
  formData,
  placeholder,
  onChange,
  isSearch,
  isConfirmPassword = false,
  onBlur,
  maxLength = 255,
  disabled,
  buttonParameters,
  variantError = "aside" /* aside | topright | bottomleft */,
  customInputStyle = {},
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animation, setAnimation] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 200);
  }, [formData]);

  const handleChange = (e) => {
    setAnimation(false);
    onChange(e);
  };

  const getType = () => {
    if (type === "password") {
      if (isVisible) {
        return "text";
      } else {
        return "password";
      }
    }
    return type || "text";
  };

  const handleShow = () => {
    setIsVisible((prev) => !prev);
  };

  const isVisibleEye = type === "password";

  const getEyeStyle = () => {
    if (formData?.touched && formData?.hasError && variantError === "aside") {
      return {
        //paddingRight: '35px'
      };
    }
    return {};
  };
  const getInputErrorStyle = () => {
    if (formData?.hasError && formData?.touched && variantError === "aside") {
      return {
        boxShadow: "0px 1.5px 0px #BE1111CC",
        border: "initial",
      };
    } else {
    }
  };

  return (
    <div>
      <div className="flex-sb">
        <div
          style={{ ...getInputErrorStyle(), ...customInputStyle }}
          className={`custom-input ${
            formData?.hasError && formData?.touched ? "custom-input_error" : ""
          }`}
          ref={ref}
        >
          <input
            className={`custom-input__input ${
              formData?.hasError && formData?.touched
                ? "custom-input__input_error"
                : ""
            }`}
            type={getType()}
            placeholder={placeholder || ""}
            value={formData?.value || ""}
            onChange={handleChange}
            maxLength={maxLength}
            onBlur={onBlur}
            disabled={disabled}
            {...props}
          />

          {isSearch && (
            <div className="custom-input-icon-container">
              <img alt="search" src={search} className="custom-input-icon" />
            </div>
          )}

          {isVisibleEye && (
            <div
              className="custom-input-icon-container__white pointer"
              onClick={handleShow}
              style={getEyeStyle()}
            >
              <img
                alt="eye"
                src={isVisible ? eyeCrossedOut : eye}
                className="custom-input-icon"
              />
            </div>
          )}

          {formData?.touched &&
            formData?.hasError &&
            variantError === "aside" && (
              <>
                <img
                  className={`custom-input__image ${
                    animation ? "custom-input__image_animated" : ""
                  }`}
                  src={dangerIcon}
                  alt="error"
                />
                {formData?.error && (
                  <div
                    className={`custom-input__err-container ${
                      animation ? "custom-input__err-container_animated" : ""
                    }`}
                  >
                    <div className="custom-input__err-text">
                      {formData?.error}
                    </div>
                  </div>
                )}
              </>
            )}
        </div>
        {isConfirmPassword ? (
          <CustomButton
            type="button"
            name={"Copy Password to Clipboard"}
            variant="grey"
            onClick={buttonParameters}
          />
        ) : (
          <CustomButton
            type="button"
            name={"Generate Password"}
            variant="grey"
            onClick={buttonParameters}
          />
        )}
      </div>
      {formData?.touched &&
        formData?.hasError &&
        ["topright", "bottomleft"].includes(variantError) && (
          // <div className={`custom-input__err add-edit-collaborator-modal__input-container ${variantError}`}>
          <div
            className={`custom-input__err add-edit-collaborator-modal__input-container `}
          >
            {formData?.error}
          </div>
        )}
    </div>
  );
};
