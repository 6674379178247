import React, { useState, useRef, useEffect } from "react";
import { ImageComponent } from "../../../components/ImageComponent/ImageComponent";
import { useLocation } from "react-router-dom";
import { ReactComponent as Play } from "../../../assets/icons/play.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import "./space.scss";
import ModalPoint from "../../../components/ModalPoint/ModalPoint";
import { useDispatch, useSelector } from "react-redux";
import {
  createPoi,
  getImageForMinimap,
  getMiniMap,
} from "../../../crud/spaces/spaces";
import {
  hideLoaderAction,
  setSpaceNotReady,
  setSpaceReady,
  showLoaderAction,
  showSimpleModalAction,
} from "../../../redux/actions";
import { fillFormAction, toNull } from "../../../helpers/formUtils";
import hexRgb from "hex-rgb";
import { Downloads } from "./SpaceTabs/Downloads";
import ModalTile from "../Modals/ModaTile/ModalTile";
import { replaceDevelopmentUrl } from "../../../helpers/urlUtils";

// need fix
const sdkVersion = "3.5";

let intervalHandler = null;

export const Space = ({
  module,
  playSpace,
  setPlaySpace,
  showcase,
  setShowCase,
  poiEdit,
  hideAdd,
  poiList,
  setPoiList,
  spaceData,
  poiPage,
  setPoiEdit,
  openDeleteModal,
  downloadPage,
  setUpdateIsEdit,
  updateIsEdit,
  setSpaceData,
  updateMiniMap,
  setUpdateMiniMap,
  isMinimap,
}) => {
  const ref = useRef();
  const location = useLocation();
  const [toolTip, setToolTip] = useState(false);
  const [active, setActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [coords, setCoords] = useState(null);
  const [newTags, setNewTags] = useState([]);
  const [load, setLoad] = useState(false);
  const [floor, setFloor] = useState([]);
  const [miniMap, setMiniMap] = useState([]);
  const dispatch = useDispatch();
  const IsInsidePoi1 = useSelector((state) => state.helpers.isSpaceInsidePoi1);
  const color = useSelector((state) => state.profile.color);
  const matterportSdkKey = useSelector(
    (state) => state.profile.matterport_sdk_key
  );

  showcase?.App?.state?.subscribe(function (appState) {
    if (appState.phase === showcase.App.Phase.PLAYING) {
      dispatch(setSpaceReady());
    }
  });
  useEffect(() => {
    return () => {
      dispatch(setSpaceNotReady());
    };
  }, []);
  useEffect(() => {
    if (updateIsEdit) {
      setIsEdit((prev) => !prev);
      setUpdateIsEdit(false);
    }
  }, [updateIsEdit]);
  const addPoi = (data, id) => {
    const updatedMediaUrl = replaceDevelopmentUrl(data?.mediaUrl);
    setPoiEdit(
      fillFormAction({
        name: { value: data?.name, touched: false, hasError: true, error: "" },
        description: {
          value: data?.description,
          touched: false,
          hasError: true,
          error: "",
        },
        backgroundColor: {
          value: data?.backgroundColor,
          touched: false,
          hasError: true,
          error: "",
        },
        opacity: {
          value: data?.opacity,
          touched: false,
          hasError: true,
          error: "",
        },
        size: { value: data?.size, touched: false, hasError: true, error: "" },
        categories: {
          value: data?.categories || [],
          touched: false,
          hasError: true,
          error: "",
        },
        mediaType: {
          value: data?.mediaType,
          touched: false,
          hasError: true,
          error: "",
        },
        mediaUrl: {
          value: updatedMediaUrl,
          touched: false,
          hasError: true,
          error: "",
        },
        mouseAction: {
          value: data?.mouseAction,
          touched: false,
          hasError: true,
          error: "",
        },
        matterPortId: {
          value: data?.matterPortId || id,
          touched: false,
          hasError: true,
          error: "",
        },
        id: { value: data?.id, touched: false, hasError: true, error: "" },
        isFormValid: false,
        x: { value: data?.x, touched: false, hasError: true, error: "" },
        y: { value: data?.y, touched: false, hasError: true, error: "" },
        z: { value: data?.z, touched: false, hasError: true, error: "" },
        floor: {
          value: data?.floor,
          touched: false,
          hasError: true,
          error: "",
        },
        createdAt: {
          value: data?.createdAt,
          touched: false,
          hasError: true,
          error: "",
        },
        enabled: {
          value: data?.enabled,
          touched: false,
          hasError: true,
          error: "",
        },
        image: {
          value: { origin: data?.image },
          touched: false,
          hasError: true,
          error: "",
        },
        icon: { value: data?.icon, touched: false, hasError: true, error: "" },
        modalSize: {
          value: data?.modalSize,
          touched: false,
          hasError: true,
          error: "",
        },
        stemVector: {
          value: data?.stemVector,
          touched: false,
          hasError: true,
          error: "",
        },
        stemVisible: {
          value: data?.stemVisible,
          touched: false,
          hasError: true,
          error: "",
        },
        stemLength: {
          value: data?.stemLength,
          touched: false,
          hasError: true,
          error: "",
        },
      })
    );
    showcase?.Mattertag?.navigateToTag(id, showcase.Mattertag.Transition.FLY);
    poiPage("step2");
    setNewTags([]);
  };

  // Find item
  const findTag = (id) => {
    let curPoi = poiList?.find((poi) => poi.matterPortId === id);
    return curPoi;
  };
  useEffect(() => {
    if (ref && playSpace) {
      async function onshowcaseConnect(mpSdk) {
        setShowCase(mpSdk);
        try {
          const modelData = await mpSdk.Model.getData();
          setLoad(true);
        } catch (e) {
          console.error(e);
        }
      }

      const handlePlaySpace = async () => {
        const iframe = document.getElementById("showcase-iframe");
        try {
          const mpSdk = await window.MP_SDK.connect(
            iframe,
            "", // key
            "" // Unused but needs to be a valid string
          );
          onshowcaseConnect(mpSdk);
        } catch (e) {
          console.error("handlePlaySpace", e);
        }
      };

      handlePlaySpace();
    }
  }, [playSpace, ref]);
  const loadMiniMap = async (type) => {
    dispatch(showLoaderAction());
    getMiniMap(spaceData.id)
      .then((res) => {
        let miniMap = res.data.reverse();
        setMiniMap(miniMap);
        if (type == "rel") {
          let currFloor = res.data?.find((map) => map.floor === floor.floor);
          setFloor(currFloor);
        }
      })
      .catch((err) => {
        const errors = err?.response?.data;
        if (!errors) return;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(() => {
        dispatch(hideLoaderAction());
      });
  };
  useEffect(() => {
    if (spaceData?.id) {
      loadMiniMap();
    }
  }, [spaceData?.id]);

  useEffect(() => {
    if (showcase) {
      showcase?.Floor.current.subscribe(function (currentFloor) {
        // Change to the current floor has occurred.
        if (currentFloor.sequence === -1) {
          // console.log("Currently viewing all floors");
          console.log("");
        } else if (currentFloor.sequence === undefined) {
          if (currentFloor.id === undefined) {
            // console.log("Current viewing an unplaced unaligned sweep");
            console.log("");
          } else {
            // console.log("Currently transitioning between floors");
            console.log("");
          }
        } else {
          let currFloor = parseInt(currentFloor.id) + 1;
          let findMiniMap = miniMap?.find((map) => map?.floor === currFloor);
          setFloor(findMiniMap);
        }
      });
    }
  }, [load, showcase]);

  useEffect(() => {
    if (updateMiniMap) {
      loadMiniMap("rel");
      setUpdateMiniMap(false);
    }
  }, [updateMiniMap]);

  useEffect(() => {
    if (showcase && module === "Point of Interests (POIs)" && active) {
      // detect focus on iframe and deactivate button
      window.focus();
      const handler = () => {
        setTimeout(() => {
          if (document.activeElement.tagName === "IFRAME") {
            setActive(false);
            setIsEdit(false);
          }
        });
      };
      window.addEventListener("blur", handler, { once: true });

      // connect to SDK
      const button = document.getElementById("iframe-button");
      button.style.backgroundColor = color;
      const iframe = document.getElementById("showcase-iframe");

      window.MP_SDK.connect(iframe, matterportSdkKey, sdkVersion).then(
        async function (theSdk) {
          var sdk = theSdk;
          var poseCache;
          sdk.Camera.pose.subscribe(function (pose) {
            poseCache = pose;
          });

          var intersectionCache;
          sdk.Pointer.intersection.subscribe(function (intersection) {
            intersectionCache = intersection;
            intersectionCache.time = new Date().getTime();
            button.style.display = "none";
            buttonDisplayed = false;
          });
          var delayBeforeShow = 100;
          var buttonDisplayed = false;

          intervalHandler = setInterval(() => {
            if (!intersectionCache || !poseCache) {
              return;
            }
            if (newTags.length) {
              // console.log('getDiscPosition(newTags[0].tagId)', showcase.Mattertag.getDiscPosition(newTags[0].tagId));
              // console.log(intersectionCache?.position?.x, showcase.Mattertag.getDiscPosition(newTags[0].tagId)?.position?.x);
            }
            const nextShow = intersectionCache.time + delayBeforeShow;
            if (new Date().getTime() > nextShow) {
              if (buttonDisplayed) {
                return;
              }
              var size = {
                w: iframe.clientWidth,
                h: iframe.clientHeight,
              };
              var coord = sdk.Conversion.worldToScreen(
                intersectionCache.position,
                poseCache,
                size
              );
              button.style.left = `${Math.abs(coord.x) + 596}px`;
              button.style.top = `${Math.abs(coord.y) + 80}px`;
              button.style.display = "block";
              buttonDisplayed = true;
            }
          }, 16);

          button.addEventListener("click", function () {
            setCoords(intersectionCache);
          });
        }
      );

      return () => {
        window.removeEventListener("blur", handler);
        clearInterval(intervalHandler);
        intervalHandler = null;
      };
    }
  }, [showcase, module, active, color]);

  useEffect(() => {
    if (newTags.length) {
      const poi = {
        matterPortId: null,
        name: "",
        description: "",
        backgroundColor: "#03687d",
        opacity: 100,
        size: 50,
        mediaType: "photo",
        floor: 2,
        enabled: true,
        mouseAction: false,
        x: 0,
        y: 0,
        z: 0,
        createdAt: new Date(),
        categories: [],
        stemVisible: true,
        stemLength: 0.3,
      };
      showcase?.Mattertag.getData(newTags[0].tagId).then((res) => {
        let copy = [...res];
        let lastElement = copy.pop();
        poi.x = lastElement?.anchorPosition?.x;
        poi.z = lastElement?.anchorPosition?.z;
        poi.y = lastElement?.anchorPosition?.y;
        poi.floor = lastElement?.floorIndex + 1;
        poi.stemVector = newTags[0].stemVector;
        addPoi(poi, newTags[0].tagId);
      });
    }
  }, [newTags]);

  const addTag = () => {
    // example
    const tag = {
      label: "",
      description: "",
      anchorPosition: {
        x: coords.position.x,
        y: coords.position.y,
        z: coords.position.z,
      },
      stemVector: {
        x: coords.normal.x,
        y: coords.normal.y,
        z: coords.normal.z,
      },
      color: {
        // blue disc
        r: 0.01176470588235294,
        g: 0.40784313725490196,
        b: 0.49019607843137253,
      },
    };

    showcase.Mattertag.add([tag])
      .then((sid) => {
        setIsEdit(false);
        setActive(false);
        setNewTags((prev) => [...prev, { tagId: sid[0], ...tag }]);
        showcase.Mattertag.editStem(sid[0], { stemHeight: 0.3 });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handlePlaySpace = () => {
    setPlaySpace(true);
  };

  const isFormBlock =
    module !== "Set Starting Location" && module !== "Take Photos";
  const isDownloads = module === "Downloads";
  //Render poi when space is ready
  async function renderPoi() {
    await new Promise((resolve) => setTimeout(resolve), 2000);
    // If space has POI and we delete from ourList need to delete forSpace
    showcase?.Mattertag.getData().then(function (mattertags) {
      showcase.Tag.toggleDocking(false);
      showcase.Tag.toggleNavControls(false);
      showcase.Tag.toggleSharing(false);
      let getList = poiList;
      mattertags?.forEach((origMatt) => {
        if (getList?.find((list) => list?.matterPortId === origMatt?.sid)) {
        } else {
          showcase?.Mattertag.remove(origMatt.sid);
        }
      });
    });
    let poiRender = poiList?.map((poi) => {
      if (poi?.matterPortId) {
        let colorToRgb = hexRgb(poi?.backgroundColor);
        let r = colorToRgb.red / 255;
        let g = colorToRgb.green / 255;
        let b = colorToRgb.blue / 255;
        showcase.Mattertag.editStem(poi.matterPortId, {
          stemVisible: poi?.stemVisible,
          stemHeight: poi?.stemLength,
        });
        showcase?.Mattertag.editColor(poi?.matterPortId, {
          r,
          g,
          b,
        });
        if (poi?.icon) {
          let iconUrl = poi.icon.includes("dev.api.akrotonx.com")
            ? poi.icon.replace(
                "dev.api.akrotonx.com",
                "akrotonx-multimedia.fra1.digitaloceanspaces.com"
              )
            : poi.icon.includes("development.api.akrotonx.com")
            ? poi.icon.replace(
                "development.api.akrotonx.com",
                "akrotonx-multimedia.fra1.digitaloceanspaces.com"
              )
            : poi.icon;
          let math = Math.random()?.toFixed(3);
          showcase?.Mattertag.registerIcon(
            `${poi?.matterPortId}-${math}`,
            iconUrl
          ).then((res) => {
            showcase.Mattertag.editIcon(
              poi?.matterPortId,
              `${poi?.matterPortId}-${math}`
            );
          });
        } else {
        }
        if (poi?.enabled) {
          //let opacity = poi?.opacity / 100
          //showcase.Mattertag.editOpacity(poi?.matterPortId, opacity)
        } else {
          showcase.Mattertag.editOpacity(poi?.matterPortId, 0);
        }
        return poi;
      } else {
        let colorToRgb = hexRgb(poi?.backgroundColor);
        let r = colorToRgb.red / 255;
        let g = colorToRgb.green / 255;
        let b = colorToRgb.blue / 255;
        const tag = {
          label: poi?.name,
          description: "",
          anchorPosition: { x: poi.x, y: poi.y, z: poi.z },
          stemVector: poi?.stemVector || { x: 0, y: 0, z: 0 },
          stemVisible: poi?.stemVisible,
          color: { r, g, b },
        };
        showcase.Mattertag.add([tag]).then((sid) => {
          /*showcase?.Mattertag.preventAction(sid[0], {
              opening: true,
            })*/
          if (poi?.icon) {
            let iconUrl = poi.icon.includes("dev.api.akrotonx.com")
              ? poi.icon.replace(
                  "dev.api.akrotonx.com",
                  "akrotonx-multimedia.fra1.digitaloceanspaces.com"
                )
              : poi.icon.includes("development.api.akrotonx.com")
              ? poi.icon.replace(
                  "development.api.akrotonx.com",
                  "akrotonx-multimedia.fra1.digitaloceanspaces.com"
                )
              : poi.icon;
            let math = Math.random()?.toFixed(3);
            showcase?.Mattertag.registerIcon(`${sid[0]}-${math}`, iconUrl).then(
              (res) => {
                showcase.Mattertag.editIcon(sid[0], `${sid[0]}-${math}`);
              }
            );
          } else {
          }
          if (poi?.enabled) {
            // let opacity = poi?.opacity / 100
            // showcase.Mattertag.editOpacity(sid[0], opacity)
          } else {
            showcase.Mattertag.editOpacity(sid[0], 0);
          }
          showcase.Mattertag.editStem(sid[0], {
            stemHeight: poi?.stemLength,
          });

          poi.matterPortId = sid[0];
        });
        return poi;
      }
    });
    await poiRender;
  }

  const handleChangeFloor = (e, item) => {
    dispatch(showLoaderAction());
    let moveToFloor = item?.floor - 1;
    showcase.Floor.moveTo(moveToFloor)
      .then((res) => {})
      .finally(() => {
        dispatch(hideLoaderAction());
      });
  };
  // function for click metateg
  // When space is ready
  useEffect(() => {
    if (load && showcase) {
      renderPoi();
    }
  }, [load, showcase]);
  useEffect(() => {
    if (load && showcase) {
      showcase.Tag.openTags.subscribe({
        prevState: {
          hovered: null,
          docked: null,
          selected: null,
        },
        onChanged(newState) {
          if (newState.hovered !== this.prevState.hovered) {
            if (newState.hovered) {
              //was hovered
              let modalDate = findTag(newState?.hovered);
              if (modalDate?.mouseAction) {
                showcase.Tag.open(newState?.hovered);
                let mutate = modalDate?.categories?.reduce((arr, category) => {
                  let item = {
                    name: category?.title,
                    value: category?.id,
                  };
                  arr.push(item);
                  return arr;
                }, []);
                console.log("mutate", modalDate);
                setPoiEdit(
                  fillFormAction({
                    name: {
                      value: modalDate?.name,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    description: {
                      value: modalDate?.description,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    backgroundColor: {
                      value: modalDate?.backgroundColor,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    opacity: {
                      value: modalDate?.opacity,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    size: {
                      value: modalDate?.size,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    categories: {
                      value: mutate || [],
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    mediaType: {
                      value: modalDate?.mediaType,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    mediaUrl: {
                      value: modalDate?.mediaUrl,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    mouseAction: {
                      value: modalDate?.mouseAction,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    matterPortId: {
                      value: modalDate?.matterPortId,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    id: {
                      value: modalDate?.id,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    isFormValid: false,
                    x: {
                      value: modalDate?.x,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    y: {
                      value: modalDate?.y,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    z: {
                      value: modalDate?.z,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    floor: {
                      value: modalDate?.floor,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    createdAt: {
                      value: modalDate?.createdAt,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    enabled: {
                      value: modalDate?.enabled,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    image: {
                      value: { origin: modalDate?.image },
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    icon: {
                      value: modalDate?.icon,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    modalSize: {
                      value: modalDate?.modalSize,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    stemVector: {
                      value: modalDate?.stemVector,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    stemVisible: {
                      value: modalDate?.stemVisible,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                    stemLength: {
                      value: modalDate?.stemLength,
                      touched: false,
                      hasError: true,
                      error: "",
                    },
                  })
                );
              }
            } else {
              // is no longer hovered
            }
          }
          /*if (newState.docked !== this.prevState.docked) {
            if (newState.docked) {
              console.log(newState.docked, 'was docked');
            } else {
              console.log(this.prevState.docked, 'was undocked');
            }
          }*/

          // only compare the first 'selected' since only one tag is currently supported
          const [selected = null] = newState.selected; // destructure and coerce the first Set element to null
          if (selected !== this.prevState.selected) {
            if (selected) {
              let modalDate = findTag(selected);
              if (!modalDate) return;
              let mutate = modalDate?.categories?.reduce((arr, category) => {
                let item = {
                  name: category?.title,
                  value: category?.id,
                };
                arr.push(item);
                return arr;
              }, []);
              setPoiEdit(
                fillFormAction({
                  name: {
                    value: modalDate?.name,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  description: {
                    value: modalDate?.description,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  backgroundColor: {
                    value: modalDate?.backgroundColor,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  opacity: {
                    value: modalDate?.opacity,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  size: {
                    value: modalDate?.size,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  categories: {
                    value: mutate || [],
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  mediaType: {
                    value: modalDate?.mediaType,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  mediaUrl: {
                    value: modalDate?.mediaUrl,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  mouseAction: {
                    value: modalDate?.mouseAction,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  matterPortId: {
                    value: modalDate?.matterPortId,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  id: {
                    value: modalDate?.id,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  isFormValid: false,
                  x: {
                    value: modalDate?.x,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  y: {
                    value: modalDate?.y,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  z: {
                    value: modalDate?.z,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  floor: {
                    value: modalDate?.floor,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  createdAt: {
                    value: modalDate?.createdAt,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  enabled: {
                    value: modalDate?.enabled,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  image: {
                    value: { origin: modalDate?.image },
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  icon: {
                    value: modalDate?.icon,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  modalSize: {
                    value: modalDate?.modalSize,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  stemVector: {
                    value: modalDate?.stemVector,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  stemVisible: {
                    value: modalDate?.stemVisible,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                  stemLength: {
                    value: modalDate?.stemLength,
                    touched: false,
                    hasError: true,
                    error: "",
                  },
                })
              );
              //console.log(selected, 'was selected');
            } else {
              // was deselected
            }
          }

          // clone and store the new state
          this.prevState = {
            ...newState,
            selected,
          };
        },
      });
    }
  }, [load, showcase, poiList]);

  const widthOfFrame = () => {
    if (module && module === "Access Settings") {
      return "calc(100vw - 795px)";
    } else if (module && isFormBlock) {
      return "calc(100vw - 634px)";
    } else {
      return "100%";
    }
  };
  const widthOfContainer = () => {
    if (module && module === "Access Settings") {
      return "calc(100vw - 795px)";
    } else if (module && isFormBlock && !isDownloads) {
      return "calc(100vw - 634px)";
    } else {
      return "calc(100% - 50px)";
    }
  };

  const widthOfFloorContainer = () => {
    if (module && module === "Access Settings") {
      return "10px";
    } else if (module && isFormBlock && !isDownloads) {
      return "10px";
    } else {
      return "60px";
    }
  };

  return (
    <>
      {module === "Point of Interests (POIs)" && active && (
        <div
          id="iframe-button"
          onClick={addTag}
          style={{ backgroundColor: color }}
        />
      )}

      {playSpace ? (
        <div
          className="space__iframe-container"
          ref={ref}
          style={{ width: widthOfContainer() }}
        >
          {module === "Point of Interests (POIs)" && hideAdd && (
            <div className="poi-tab__button">
              {!active && (
                <div
                  className={`poi-tab__tooltip ${
                    toolTip ? "poi-tab__tooltip_active" : ""
                  }`}
                >
                  Click the + button to add a <br />
                  POI
                </div>
              )}

              {isEdit && (
                <div
                  className="poi-tab__button-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!poiEdit) {
                      setIsEdit((prev) => !prev);
                      return;
                    }
                    poiPage("step2");
                  }}
                >
                  <Edit alt="edit" fill="#fff" />
                </div>
              )}

              <div
                className="poi-tab__button-elem"
                onMouseOver={() => {
                  !isEdit && setToolTip(true);
                }}
                onMouseLeave={() => setToolTip(false)}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEdit(false);
                  setActive((prev) => !prev);
                  setToolTip(false);
                }}
              >
                <Plus
                  alt="plus"
                  className={`poi-tab__plus ${
                    active ? "poi-tab__plus_active" : ""
                  }`}
                  fill={color}
                />
              </div>

              {isEdit && (
                <div
                  className="poi-tab__button-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!poiEdit) {
                      setIsEdit((prev) => !prev);
                      return;
                    }
                    let elem = {
                      ...poiEdit,
                      id: poiEdit?.id?.value,
                      matterPortId: poiEdit?.matterPortId?.value,
                    };
                    openDeleteModal(elem);
                  }}
                >
                  <Trash alt="delete" fill="#fff" />
                </div>
              )}
            </div>
          )}
          {
            /*module === 'Point of Interests (POIs)' &&*/ poiEdit &&
              !(module === "Downloads") &&
              !(module === "Tile Menu") &&
              !IsInsidePoi1 && (
                <ModalPoint
                  module={module}
                  poiEdit={poiEdit}
                  setPoiEdit={setPoiEdit}
                  hideAdd={hideAdd}
                />
              )
          }
          {miniMap.length > 0 &&
            !(module === "Downloads") &&
            !(module === "Mini Map") && (
              <div
                style={{ left: widthOfFloorContainer() }}
                className="space-floor"
              >
                {miniMap.map((item) => {
                  const spaceFloorItemClassname =
                    floor?.floor === item?.floor
                      ? "space-floor__item space-floor__item--active"
                      : "space-floor__item";
                  return (
                    <span
                      key={item.floor}
                      onClick={(e) => handleChangeFloor(e, item)}
                      className={spaceFloorItemClassname}
                      style={
                        spaceFloorItemClassname.includes("active")
                          ? { color }
                          : {}
                      }
                    >
                      {item.floor}
                    </span>
                  );
                })}
              </div>
            )}
          {module === "Tile Menu" && (
            <ModalTile
              showcase={showcase}
              spaceData={spaceData}
              setSpaceData={setSpaceData}
            />
          )}
          <iframe
            style={{
              width: widthOfFrame(),
              height: "calc(100vh - 120px)",
              backgroundColor: "grey",
              marginLeft: module && isFormBlock ? "" : "50px",
              cursor: "pointer",
              display: `${module === "Downloads" ? "none" : "block"}`,
            }}
            src={`https://my.matterport.com/show${
              location.search
            }&play=1&&search=0title=0&applicationKey=${matterportSdkKey}${
              spaceData?.additionalposition !== null
                ? spaceData?.additionalposition
                : ""
            }`}
            frameBorder="0"
            className={module === "Mini Map" ? "iframe-hide" : ""}
            allow="xr-spatial-tracking;"
            id="showcase-iframe"
          ></iframe>
          {module === "Downloads" && (
            <Downloads spaceData={spaceData}></Downloads>
          )}
        </div>
      ) : (
        <div
          style={{
            width:
              module && isFormBlock
                ? "calc(100vw - 634px)"
                : "calc(100% - 50px)",
            marginLeft: module && isFormBlock ? "" : "50px",
          }}
          className="space__preview-container"
        >
          <ImageComponent
            src={`admin/spaces/${
              location.pathname.split("/")[3]
            }/preview?mode=origin`}
          />
          <div
            className="space__preview-play-btn"
            onClick={handlePlaySpace}
            style={{ border: `5px solid ${color}` }}
          >
            <Play fill={color} />
          </div>
        </div>
      )}
    </>
  );
};
