import React from 'react';
import { useSelector } from 'react-redux';
import './radio.scss';

export const CustomRadio = ({ data, value, onChange, isRadioList = false}) => {
  const handleChange = elem => {
    onChange(elem);
  };
  const color = useSelector(state => state.profile.color);

  if(isRadioList) {
    return (
      <div className="custom-radio">
        {data.map(elem => {
          return (
            <div
              className="custom-radio__elem"
              key={elem.id}
              onClick={() => handleChange(elem)}
            >
              <div
                className={`custom-radio__checkbox ${
                  value?.id === elem.id
                    ? 'custom-radio__checkbox_checked'
                    : ''
                }`}
                style={{ border: `2px solid ${color}` }}
              >
                {value?.id === elem.id && (
                  <div
                    className="custom-radio__dot"
                    style={{ backgroundColor: color }}
                  />
                )}
              </div>
              <div className="custom-radio__title">{elem.name || ''}</div>
            </div>
          );
        })}
      </div>
    );
  }else{
    return (
      <div className="custom-radio">
        {data.map(elem => {
          return (
            <div
              className="custom-radio__elem"
              key={elem.name}
              onClick={() => handleChange(elem)}
            >
              <div
                className={`custom-radio__checkbox ${
                  value?.name === elem.name
                    ? 'custom-radio__checkbox_checked'
                    : ''
                }`}
                style={{ border: `2px solid ${color}` }}
              >
                {value?.name === elem.name && (
                  <div
                    className="custom-radio__dot"
                    style={{ backgroundColor: color }}
                  />
                )}
              </div>
              <div className="custom-radio__title">{elem.name || ''}</div>
            </div>
          );
        })}
      </div>
    );
  }
};