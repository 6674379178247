import React, { useEffect, useState, useRef } from "react";
import { SpacesTable } from "./SpacesTable";
import { CustomBreadCrumbs } from "../../../components/CustomBreadCrumbs/CustomBreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoaderAction,
  showLoaderAction,
  showSimpleModalAction,
} from "../../../redux/actions";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { useLocation } from "react-router-dom";
import {
  getFolders,
  getFoldersAll,
  moveFolderTo,
} from "../../../crud/spaces/folders";
import { ModalCreateEditFolder } from "../Modals/ModalCreateEditFolder/ModalCreateEditFolder";
import { ModalDelete } from "../Modals/ModalDelete/ModalDelete";
import { RouterPagination } from "../../../components/RouterPagination/RouterPagination";
import { CustomCheckbox } from "../../../components/CustomCheckbox/CustomCheckbox";
import smallArrow from "../../../assets/icons/small-arrow.png";
import { SimpleInput } from "../../../components/CustomInput/SimpleInput";
import { useIsMount } from "../../../hooks/useIsMount";
import { CustomFetchDropdown } from "../../../components/CustomDropdown/CustomFetchDropdown";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import trash from "../../../assets/icons/trash.svg";
import moveFolder from "../../../assets/icons/move-folder.svg";
import { fetchUsers } from "../../../crud/users";
import "./spaces-page.scss";
import { fetchCollaborators } from "../../../crud/collaborators";
import { ModalSpaceStatus } from "../Modals/ModalSpaceStatus/ModalSpaceStatus";
import { ModalSpaceAdd } from "../Modals/ModalSpaceAdd/ModalSpaceAdd";
import { ModalManageCollabSpace } from "../Modals/ModalManageCollabSpace/ModalManageCollabSpace";
import { ModalManageUserSpace } from "../Modals/ModalManageUserSpace/ModalManageUserSpace";
import ModalSettingsSpace from "../Modals/ModalSettingsSpace/ModalSettingsSpace";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FolderIcon from "@mui/icons-material/Folder";
// import TreeItem from "@mui/lab/TreeItem";

export const SpacesPage = ({ pageTitle }) => {
  const role = useSelector((state) => state.profile.role);
  const color = useSelector((state) => state.profile.color);
  const isFirstRender = useIsMount();
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef();
  const treeRef = useRef();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [breadCrumbs, setBreadCrumbs] = useState([
    { title: "Main", path: "/home/spaces?page=1" },
  ]);
  const [modalCreateFolder, setModalCreateFolder] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState({ name: "10", value: 10 });
  const [count, setCount] = useState(0);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [check, setCheck] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);
  const [thisFolder, setThisFolder] = useState(null);
  const [current, setCurrent] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [collabsList, setCollabsList] = useState([]);
  const [userFilter, setUserFilter] = useState(null);
  const [collaboratorFilter, setCollaboratorFilter] = useState(null);
  const [modalSpaceStatus, setModalSpaceStatus] = useState(false);
  const [showTree, setShowTree] = useState(false);
  const [searchFolder, setSearchFolder] = useState("");
  const [isCreateSpaceModal, setIsCreateSpaceModal] = useState(false);
  const [modalManageCollaborators, setModalManageCollaborators] =
    useState(false);
  const [modalManageUsers, setModalManageUsers] = useState(false);
  const [modalSettings, setModalSettings] = useState(false);
  const [currentSingle, setCurrentSingle] = useState(null);
  const [variantForDeleteModal, setVariantForDeleteModal] = useState("");
  const [allFolders, setAllFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(undefined);

  useClickOutside(buttonRef, () => {
    if (open) setOpen(false);
  });

  useClickOutside(treeRef, () => {
    if (showTree) {
      setSelectedFolder(undefined);
      setShowTree(false);
    }
  });

  // get page number & load table data
  useEffect(() => {
    if (location && page === 0) setPage(+location.search.split("page=")[1]);
    if (page >= 1) loadData();
  }, [orderDirection, page, perPage, location, userFilter, collaboratorFilter]);

  // render breadcrubms
  useEffect(() => {
    let locationArray = location.pathname.split("?")[0].split("/");
    locationArray.splice(0, 3);
    let breadCrumbs = [{ title: "Main", path: "/home/spaces?page=1" }];
    if (locationArray.length > 0) {
      locationArray.forEach((elem, index) => {
        breadCrumbs.push({
          title: elem,
          path: `${breadCrumbs[index].path}/${elem}`,
        });
      });
    }
    setBreadCrumbs(breadCrumbs);
  }, [location]);
  // need update when search, when delete, when move

  const loadFolders = () => {
    dispatch(showLoaderAction());
    getFoldersAll({
      params: {
        search: searchFolder,
      },
    }).then((res) => {
      dispatch(hideLoaderAction());
      setAllFolders(res.data);
    });
  };

  const checkIfDescendant = (
    foldersList,
    parentsIdList,
    idFoldersToMove,
    idDestination
  ) => {
    // console.log('---------------------------');
    for (let i = 0; i < foldersList.length; i++) {
      const element = foldersList[i];

      if (element.id === idDestination) {
        if (parentsIdList.some((elem) => idFoldersToMove.includes(elem))) {
          // console.log('destination is descendant');
          return true;
        } else {
          // console.log('destination is not descendant');
          return false;
        }
      } else {
        if (element.childrenFolders.length > 0) {
          parentsIdList.push(element.id);
          if (
            checkIfDescendant(
              element.childrenFolders,
              parentsIdList,
              idFoldersToMove,
              idDestination
            )
          ) {
            // console.log('destination is descendant confirmation');
            return true;
          }
          parentsIdList.pop(); // Ensure to pop the parentId if the recursive call did not return true
        } else {
          // console.log("there are no folder childrens on the element");
        }
      }
    }
    // console.log('destination is not descendant confirmation');
    return false;
  };

  const isDescendant = (splitType) => {
    if (splitType.destinationFolderId === allFolders.rootFolder.id) {
      // console.log('root folder selected, can pass');
      return false;
    } else {
      let parentsIdList = [allFolders.rootFolder.id];
      return checkIfDescendant(
        allFolders.list,
        parentsIdList,
        splitType.foldersIds,
        splitType.destinationFolderId
      );
    }
  };

  const move = () => {
    if (!selectedFolder) return;
    const splitType = check.reduce(
      (acc, curr) => {
        const findIn = data.find((item) => item.entity.id === curr);
        if (findIn.type === "folder") acc.foldersIds.push(curr);
        else acc.spacesIds.push(curr);
        return acc;
      },
      {
        foldersIds: [],
        spacesIds: [],
      }
    );
    splitType.destinationFolderId = Number(selectedFolder); // Ensure it's a number

    if (splitType.foldersIds.length > 0) {
      if (splitType.foldersIds.includes(splitType.destinationFolderId)) {
        // console.log("A folder cannot be moved into itself.");
        dispatch(
          showSimpleModalAction({
            title: "Error",
            text: "A folder cannot be moved into itself.",
          })
        );
        return;
      } else {
        if (isDescendant(splitType)) {
          if (splitType.foldersIds.length < 2) {
            dispatch(
              showSimpleModalAction({
                title: "Error",
                text: "The folder cannot be moved inside of one of their parents.",
              })
            );
          } else {
            dispatch(
              showSimpleModalAction({
                title: "Error",
                text: "One of the folders cannot be moved inside of one of their parents.",
              })
            );
          }
        } else {
          // console.log('move with folders');
          moveFolderTo(splitType)
            .then((res) => {
              loadData();
              setCheck([]);
              setShowTree(false);
            })
            .catch((err) => {
              const errors = err?.response?.data;
              let { error, message, statusCode } = errors;
              let errorMessage;
              if (Array.isArray(message)) {
                let getMessageArray = message[0].constraints;
                for (const element in getMessageArray) {
                  errorMessage = getMessageArray[element];
                }
              } else {
                errorMessage = message;
              }
              dispatch(
                showSimpleModalAction({ title: "Error", text: errorMessage })
              );
            });
        }
      }
    } else {
      // console.log('move with spaces');
      moveFolderTo(splitType)
        .then((res) => {
          loadData();
          setCheck([]);
          setShowTree(false);
        })
        .catch((err) => {
          const errors = err?.response?.data;
          let { error, message, statusCode } = errors;
          let errorMessage;
          if (Array.isArray(message)) {
            let getMessageArray = message[0].constraints;
            for (const element in getMessageArray) {
              errorMessage = getMessageArray[element];
            }
          } else {
            errorMessage = message;
          }
          dispatch(
            showSimpleModalAction({ title: "Error", text: errorMessage })
          );
        });
    }
  };

  const loadData = () => {
    setData([]);
    setThisFolder([]);
    loadFolders();
    dispatch(showLoaderAction());
    getFolders(
      {
        params: {
          search,
          userFilter: userFilter?.value?.id,
          collaboratorFilter: collaboratorFilter?.value?.id,
          orderDirection,
          order: "date",
          page,
          perPage: perPage.value,
        },
      },
      location.pathname.split("/home/spaces")[1]
    ).then((res) => {
      dispatch(hideLoaderAction());
      setData(res.data?.list);
      setThisFolder(res.data?.thisFolder);
      setCount(Math.ceil(res.data.countLeft / perPage.value + page));
      if (page !== 1 && res.data.list.length === 0) {
        setPage(1);
      }
    });
  };

  useEffect(() => {
    if (isFirstRender) return;
    setData([]);
    setThisFolder([]);
    const handler = setTimeout(() => {
      dispatch(showLoaderAction());
      getFolders(
        {
          params: {
            search,
            userFilter: userFilter?.value?.id,
            collaboratorFilter: collaboratorFilter?.value?.id,
            orderDirection,
            order: "date",
          },
        },
        location.pathname.split("/home/spaces")[1]
      ).then((res) => {
        dispatch(hideLoaderAction());
        setData(res.data?.list);
        setThisFolder(res.data?.thisFolder);
        setCount(Math.ceil(res.data.countLeft / perPage.value + page));
      });
    }, 1000);
    return () => clearTimeout(handler);
  }, [search]);

  useEffect(() => {
    if (isFirstRender) return;
    const handler = setTimeout(() => {
      dispatch(showLoaderAction());
      loadFolders();
    }, 1000);
    return () => clearTimeout(handler);
  }, [searchFolder]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const onCheck = (id) => {
    if (id === "select all") {
      if (check.length < data.length) {
        setCheck(data.map((elem) => elem.entity.id));
      } else {
        setCheck([]);
      }
    } else {
      setCheck((prev) => {
        let arr = [...prev];
        const index = prev.findIndex((item) => item === id);
        if (index === -1) {
          arr.push(id);
        } else {
          arr.splice(index, 1);
        }
        return arr;
      });
    }
  };

  const handleCurrent = (elem) => {
    if (elem) {
      setCurrent(elem);
    } else {
      setCurrent(null);
    }
  };

  const handleCreateEditFolderModal = (elem) => {
    handleCurrent(elem);
    setOpen(false);
    setModalCreateFolder((prev) => !prev);
  };

  const handleCreateSpaceModal = (elem) => {
    setOpen(false);
    setIsCreateSpaceModal((prev) => !prev);
  };

  const handleDeleteCategories = async () => {
    console.log("handleDeleteCategories called");
    let checkedFilter = check?.reduce((acc, item, index) => {
      let find = data?.find((dat) => dat?.entity?.id === item);
      if (find) {
        let objKey = `${find.type}`;
        acc[objKey] = (acc[objKey] || 0) + 1;
        return acc;
      } else {
        return acc;
      }
    }, {});

    console.log("checkedFilter:", checkedFilter);

    switch (typeof checkedFilter == "object") {
      case checkedFilter?.folder > 0 && checkedFilter?.space > 0:
        setVariantForDeleteModal({
          title: "Items",
          text: "Do you really want to delete these items from AkrotonX?",
        });
        break;
      case checkedFilter?.folder > 0:
        setVariantForDeleteModal({
          title: `${checkedFilter?.folder > 1 ? "Folders" : "Folder"}`,
          text:
            "Do you really want to remove this folder from AKROTONX? " +
            "(Everything inside the folder will also be removed. Spaces will not be removed from Matterport.)",
        });
        break;
      case checkedFilter?.space > 0:
        setVariantForDeleteModal({
          title: `${checkedFilter?.space > 1 ? "Spaces" : "Space"}`,
          text: "Do you really want to delete these spaces from AkrotonX?",
        });
        break;
      default:
        break;
    }
  };

  const handleDeleteModal = (elem) => {
    console.log("Selected IDs for deletion:", check); // Agrega este log
    if (elem) {
      setCheck([elem.entity.id]);
    } else {
      handleDeleteCategories();
    }
    setModalDelete((prev) => !prev);
  };

  const fetchUsersList = (search, page, setCanLoad, setFetching) => {
    dispatch(showLoaderAction());
    fetchUsers({ params: { page, perPage: "10", search } })
      .then((res) => {
        dispatch(hideLoaderAction());
        if (res.data.list.length < 10) setCanLoad(false);
        setUsersList((prev) => {
          const arr = res.data.list.map((elem) => {
            return {
              name: `${elem.fname} ${elem.lname}`,
              value: { ...elem },
            };
          });
          return [...prev, ...arr];
        });
      })
      .finally(() => setFetching(false));
  };

  const fetchCollabsList = (search, page, setCanLoad, setFetching) => {
    dispatch(showLoaderAction());
    fetchCollaborators({ params: { page, perPage: "10", search } })
      .then((res) => {
        dispatch(hideLoaderAction());
        if (res.data.list.length < 10) setCanLoad(false);
        setCollabsList((prev) => {
          const arr = res.data.list.map((elem) => {
            return {
              name: `${elem.fname} ${elem.lname}`,
              value: { ...elem },
            };
          });
          return [...prev, ...arr];
        });
      })
      .finally(() => setFetching(false));
  };

  const handleModalSpaceStatus = (elem) => {
    handleCurrent(elem);
    setModalSpaceStatus((prev) => !prev);
  };

  const handleTreeDropdpwn = () => {
    setTimeout(() => setShowTree((prev) => !prev), 100);
    setSearchFolder("");
  };

  const handleSearchFolder = (e) => {
    setSearchFolder(e.target.value);
  };

  const handleManageCollaboratordModal = (elem) => {
    setModalManageCollaborators((prev) => !prev);
    if (elem) {
      setCurrentSingle(elem);
    } else {
      setCurrentSingle(null);
    }
  };

  const handleManageUsersModal = (elem) => {
    setModalManageUsers((prev) => !prev);
    if (elem) {
      setCurrentSingle(elem);
    } else {
      setCurrentSingle(null);
    }
  };

  const handleSettingsModal = (elem) => {
    setModalSettings((prev) => !prev);
    if (elem) {
      setCurrentSingle(elem);
    } else {
      setCurrentSingle(null);
    }
  };

  const renderTree = (children) => {
    return children.map((subfolder) => {
      const isMore = subfolder.childrenFolders?.length > 0;
      const subfolderTitle = subfolder.title.includes("_")
        ? subfolder.title.split("_")[1]
        : subfolder.title;
      return (
        <TreeItem
          key={subfolder.id}
          nodeId={String(subfolder.id)}
          label={
            <div className="spaces-page__tree-item">
              <FolderIcon />
              {subfolderTitle}
            </div>
          }
        >
          {isMore && renderTree(subfolder.childrenFolders)}
        </TreeItem>
      );
    });
  };

  return (
    <>
      {modalManageCollaborators && (
        <ModalManageCollabSpace
          closeModal={handleManageCollaboratordModal}
          current={currentSingle}
        />
      )}
      {modalManageUsers && (
        <ModalManageUserSpace
          closeModal={handleManageUsersModal}
          current={currentSingle}
        />
      )}
      {modalSettings && (
        <ModalSettingsSpace
          closeModal={handleSettingsModal}
          current={currentSingle}
        />
      )}
      {modalCreateFolder && (
        <ModalCreateEditFolder
          closeModal={() => handleCreateEditFolderModal(null)}
          loadData={loadData}
          thisFolder={thisFolder}
          current={current}
        />
      )}
      {isCreateSpaceModal && (
        <ModalSpaceAdd
          closeModal={() => handleCreateSpaceModal(null)}
          thisFolder={thisFolder}
          loadData={loadData}
        />
      )}

      {modalDelete && (
        <ModalDelete
          loadData={loadData}
          check={check}
          setCheck={setCheck}
          closeModal={() => handleDeleteModal(null)}
          data={data}
          text={<>{variantForDeleteModal?.text}</>}
          variant={variantForDeleteModal?.title}
        />
      )}

      {modalSpaceStatus && (
        <ModalSpaceStatus
          loadData={loadData}
          closeModal={() => handleModalSpaceStatus(null)}
          current={current}
        />
      )}

      <div className="subheader">
        <h2 className="subheader__title">{pageTitle}</h2>
        <div className="subheader__button" ref={buttonRef}>
          {role !== "collaborator" && (
            <div onClick={handleOpen} className="subheader__button-item">
              <Plus alt="plus" className="subheader__plus" fill={color} />
            </div>
          )}
          {open && (
            <div className="subheader__select">
              <div
                className="subheader__select-option"
                onClick={() => handleCreateSpaceModal(null)}
              >
                Add Space
              </div>
              {role === "user" && (
                <div
                  className="subheader__select-option"
                  onClick={() => handleCreateEditFolderModal(null)}
                >
                  Add Folder
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="spaces-page">
        <div className="spaces-page__header">
          <div className="spaces-page__filters">
            <div
              className="spaces-page__filter"
              onClick={() =>
                setOrderDirection((prev) => (prev === "asc" ? "desc" : "asc"))
              }
            >
              <div className="spaces-page__filter-title">Sorting by date</div>
              <img
                src={smallArrow}
                className={`spaces-page__filter-arrow ${
                  orderDirection === "asc"
                    ? "spaces-page__filter-arrow_active"
                    : ""
                }`}
              />
            </div>
            {role === "admin" && (
              <div className="spaces-page__filter-dropdown">
                <CustomFetchDropdown
                  variant="grey"
                  placeholder="Filter by User"
                  options={usersList}
                  setOptions={setUsersList}
                  fetchOptions={fetchUsersList}
                  onChange={(value) => setUserFilter(value)}
                  value={userFilter?.name || ""}
                  isCancelButton={true}
                />
              </div>
            )}
            {role !== "collaborator" && (
              <div className="spaces-page__filter-dropdown">
                <CustomFetchDropdown
                  variant="grey"
                  placeholder="Filter by Collaborator"
                  options={collabsList}
                  setOptions={setCollabsList}
                  fetchOptions={fetchCollabsList}
                  onChange={(value) => setCollaboratorFilter(value)}
                  value={collaboratorFilter?.name || ""}
                  isCancelButton={true}
                />
              </div>
            )}
            <div className="spaces-page__filter">
              <SimpleInput
                variant="grey"
                placeholder="Search"
                value={search}
                onChange={handleSearch}
                isSearch={true}
              />
            </div>
          </div>

          {role !== "collaborator" && (
            <div className="spaces-page__actions">
              <div className="spaces-page__actions-elem">
                <CustomCheckbox
                  onChange={() => onCheck("select all")}
                  checked={check.length === data.length}
                />
              </div>

              {check.length > 0 && (
                <>
                  {role === "user" && (
                    <div
                      className="spaces-page__trash spaces-page__actions-elem"
                      onClick={handleTreeDropdpwn}
                    >
                      <img src={moveFolder} alt="move folder" />
                    </div>
                  )}
                  <div
                    className="spaces-page__trash spaces-page__actions-elem"
                    onClick={() => handleDeleteModal(null)}
                  >
                    <img src={trash} alt="delete" />
                  </div>
                </>
              )}
            </div>
          )}

          {showTree && (
            <div className="spaces-page__tree-dropdowm">
              <div ref={treeRef}>
                <div className="spaces-page__tree-title">
                  Select a destination folder
                </div>
                <div style={{ height: "38px" }}>
                  <SimpleInput
                    onChange={handleSearchFolder}
                    value={searchFolder}
                    placeholder="Type a folder name to select"
                    isSearch={true}
                  />
                </div>
                <div className="spaces-page__tree-body">
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<RemoveIcon />}
                    onNodeSelect={(n, e) => {
                      setSelectedFolder(e);
                    }}
                    defaultExpandIcon={<AddIcon />}
                    sx={{
                      maxHeight: 240,
                      flexGrow: 1,
                      maxWidth: 400,
                      overflowY: "auto",
                    }}
                  >
                    {allFolders?.rootFolder && !searchFolder && (
                      <TreeItem
                        key={allFolders?.rootFolder.id} // Añadimos la prop key
                        nodeId={String(allFolders?.rootFolder.id)} // Convertimos el nodeId a string
                        label={
                          <div className="spaces-page__tree-item">
                            <FolderIcon />
                            Main
                          </div>
                        }
                      ></TreeItem>
                    )}
                    {allFolders?.list?.map((folder) => {
                      const isMore = folder.childrenFolders.length > 0;
                      const isRoot = folder.title === "root";
                      const folderTitle = folder.title.includes("_")
                        ? folder?.title?.split("_")[1]
                        : folder?.title;
                      return (
                        <TreeItem
                          key={folder.id}
                          nodeId={String(folder.id)}
                          label={
                            <div className="spaces-page__tree-item">
                              <FolderIcon />
                              {folderTitle}
                            </div>
                          }
                        >
                          {!isRoot &&
                            isMore &&
                            renderTree(folder.childrenFolders)}
                        </TreeItem>
                      );
                    })}
                  </TreeView>
                </div>
                <div className="spaces-page__tree-buttons">
                  <div
                    className="spaces-page__tree-button"
                    onClick={() => {
                      handleTreeDropdpwn();
                      setSelectedFolder(undefined);
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    className="spaces-page__tree-button"
                    onClick={move}
                    style={{ color }}
                  >
                    Move
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {breadCrumbs.length > 1 && (
          <div className="spaces-page__breadcrumbs">
            <CustomBreadCrumbs data={breadCrumbs} />
          </div>
        )}

        <SpacesTable
          data={data}
          check={check}
          onCheck={onCheck}
          handleDeleteModal={handleDeleteModal}
          handleCreateEditFolderModal={handleCreateEditFolderModal}
          handleModalSpaceStatus={handleModalSpaceStatus}
          handleManageCollaboratordModal={handleManageCollaboratordModal}
          handleManageUsersModal={handleManageUsersModal}
          handleSettingsModal={handleSettingsModal}
          loadData={loadData}
          setVariantDelete={setVariantForDeleteModal}
        />

        <RouterPagination
          location={location.pathname.split("/home/spaces")[1]}
          page={+page}
          setPage={setPage}
          count={count}
          perPage={perPage}
          setPerPage={setPerPage}
          disabled={true}
        />
      </div>
    </>
  );
};
